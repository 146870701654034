import React from 'react';
import SvgIcon from '@talpanetwork/talpa-publishing-core/SvgIcon';

const overlay = (props) => (
  <SvgIcon {...props} width={121} height={240} viewBox="0 0 121 240">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3945.000000, -1981.000000)">
        <g transform="translate(3945.000000, 1981.000000)">
          <rect id="Rectangle" x="0" y="0" width="120" height="240" />
          <path
            d="M9.23705556e-14,0 C10.7994839,2.60851613 17.9623226,6.76645161 23.3470968,13.5050323 C28.9393548,20.5052903 35.1819355,32.2683871 40.2539355,46.2343226 L109.381161,240 L9.23705556e-14,240 L9.23705556e-14,0 Z"
            id="Path"
            fill="#000000"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

overlay.propTypes = SvgIcon.propTypes;

overlay.defaultProps = SvgIcon.defaultProps;

const SvgSliderHeaderCurve = {
  overlay
};

export default SvgSliderHeaderCurve;
