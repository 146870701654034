// const networkCode = process.env.NEXT_PUBLIC_NETWORKCODE;
// const brandName = 'RADIO538';
export enum IBanner {
  ARTICLE_PAGE = 'articlePage',
  SIDEBAR = 'sidebar',
  HOMEPAGE = 'homepage',
  HOMEPAGE_TOP = 'homePageTop',
  HOMEPAGE_BOTTOM = 'homePageBottom',
  HOMEPAGE_AFTER_SECOND_SECTION = 'homepageAfterSecondSection',
  ARTICLEPAGE_AFTER_FIRST_PARAGRAPH = 'articlePageAfterFirstParagraph',
  ARTICLEPAGE_AFTER_TEXT_BLOCK = 'articlePageAfterTextBlock',
  ARTICLEPAGE_BOTTOM = 'articlePageBottom',
  ARTICLEPAGE_SIDEBAR_MEDIUM = 'sidebarMedium',
  ARTICLEPAGE_TOP = 'articlePageTop',
  LARGE_BANNER = 'largeBanner',
  SMALL_BANNER = 'smallBanner',
  TRACKLIST_BANNER = 'tracklistBanner'
}

export const banners = {
  [IBanner.ARTICLE_PAGE]: {
    desktop: {
      // uri: `/${networkCode}/RADIO538_LB_ARTICLE_CONTENT_BTF_DESKTOP`,
      sizes: [728, 90],
      idAppnexus: 'd-h1'
    },

    mobile: {
      // uri: `/${networkCode}/RADIO538_MLB_ARTICLE_CONTENT_BTF_MOBILE`,
      sizes: [320, 50],
      idAppnexus: 'm-h1'
    }
  },

  [IBanner.SIDEBAR]: {
    // uri: `/${networkCode}/RADIO538_MREC_ARTICLE_SIDEBAR_ATF_DESKTOP`,
    sizes: [300, 250],
    idAppnexus: 'd-r1'
  },

  [IBanner.HOMEPAGE]: {
    desktop: {
      // uri: `/${networkCode}/RADIO538_HC_HOME_ATF_DESKTOP`,
      sizes: [970, 250],
      idAppnexus: 'd-home-h1'
    },

    mobile: {
      // uri: `/${networkCode}/RADIO538_HC_HOME_ATF_MOBILE`,
      sizes: [320, 100],
      idAppnexus: 'm-home-h1'
    }
  }
};

export const playerBanners = {
  [IBanner.LARGE_BANNER]: {
    desktop: {
      // uri: `/${networkCode}/${brandName}_MLB_PLAYER_ATF_TILE_DESKTOP`,
      sizes: [300, 250],
      idAppnexus: 'd-ptile-r1'
    },

    mobile: {
      // uri: `/${networkCode}/${brandName}_MLB_PLAYER_ATF_TILE_MOBILE`,
      sizes: [300, 250],
      idAppnexus: 'm-ptile-r1'
    }
  },

  [IBanner.SMALL_BANNER]: {
    desktop: {
      // uri: `/${networkCode}/${brandName}_LB_PLAYER_ATF_TILE_DESKTOP`,
      sizes: [236, 78],
      idAppnexus: 'd-ptile-h1'
    },

    mobile: {
      // uri: `/${networkCode}/${brandName}_LB_PLAYER_ATF_TILE_MOBILE`,
      sizes: [236, 78],
      idAppnexus: 'm-ptile-h1'
    }
  },

  [IBanner.TRACKLIST_BANNER]: {
    desktop: {
      // uri: `/${networkCode}/${brandName}_LB_PLAYER_TIMELINE_DESKTOP`,
      sizes: [300, 60],
      idAppnexus: 'd-ptimel-r1'
    },

    mobile: {
      // uri: `/${networkCode}/${brandName}_LB_PLAYER_TIMELINE_MOBILE`,
      sizes: [300, 60],
      idAppnexus: 'm-ptimel-r1'
    }
  }
};
